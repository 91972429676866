<template>
    <lay-row space="10">
      <lay-col md="24">
        <lay-panel>
          <lay-form :model="search" style="text-align: start" size="sm">
            <lay-form-item label="展示页面" prop="type" mode="inline">
                <lay-select v-model="search.type">
                    <lay-select-option value="0" label="全部"></lay-select-option>
                    <lay-select-option value="1" label="启动页面"></lay-select-option>
                    <lay-select-option value="2" label="首页轮播图"></lay-select-option>
                    <lay-select-option value="3" label="视频列表"></lay-select-option>
                    <lay-select-option value="4" label="视频播放页面"></lay-select-option>
                    <lay-select-option value="5" label="凤楼列表"></lay-select-option>
                    <lay-select-option value="6" label="凤楼详情"></lay-select-option>
                </lay-select>
            </lay-form-item>

            <lay-form-item label="状态" prop="state"  mode="inline">
                <lay-select v-model="search.state">
                    <lay-select-option value="0" label="全部"></lay-select-option>
                    <lay-select-option value="1" label="正常"></lay-select-option>
                    <lay-select-option value="2" label="下架"></lay-select-option> 
                </lay-select>
            </lay-form-item>
            <lay-form-item mode="inline">
              <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
            </lay-form-item>
  
          </lay-form>
          <div style="margin-bottom: 10px;text-align: left;">
            <lay-button size="sm" @click="changeVisible11" type="primary">新增</lay-button>
          </div>
  
          <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">
            <template #showPage="{ row }">
              <span v-if="row.showPage==1">启动页面</span>
              <span v-else-if="row.showPage==2">首页轮播图</span>
              <span v-else-if="row.showPage==3">视频列表</span>
              <span v-else-if="row.showPage==4">视频播放页面</span>
              <span v-else-if="row.showPage==5">凤楼列表</span>
              <span v-else-if="row.showPage==6">凤楼详情</span>
            </template>
  
            
            <template #state="{ row }"> 
              <lay-switch :model-value="row.state == 0 ? true : false" @change="changeState(row)" onswitch-text="正常"
              unswitch-text="下架"></lay-switch>
            </template>

            <template v-slot:thumbnail="{ row }">
            <img :src="row.thumbnail"
              @click="signleImg(row.thumbnail)" style="width:62.9px;height:80px" />
          </template>
  
            <template v-slot:operator="{ row }">
              <lay-button size="xs" type="primary" @click="updateAdvertise(row)">编辑</lay-button>
              <lay-button size="xs" type="danger" @click="deleAdvertise(row)">删除</lay-button>
            </template>
          </lay-table>
  
  
  
        </lay-panel>
  
      </lay-col>
  
    </lay-row>
    <lay-layer v-model="visible11" :shade="false" :area="['400px', '480px']" :btn="action11" title="添加/修改">
      <div style="padding: 20px;">
        <lay-form :model="advertise" ref="layFormRef11" > 
          <lay-form-item label="图片" prop="title">
            <lay-upload  @done="getUploadFile2" 
            :disabledPreview="false" :multiple="false" acceptMime="image/*"  
            :url="httpUrl+'/common/imgUpload/advertise'" field="file" :headers="headers">
            <template #preview>
              <div class="easy-wrap" v-if="advertise.thumbnail!=''">
                <img :src="advertise.thumbnail"  @click="signleImg(advertise.thumbnail)" style="width:80.9px;height:80.2px"/>
                
              </div>
            </template>
          </lay-upload>
          </lay-form-item> 
          <lay-form-item label="标题" prop="title">
            <lay-input v-model="advertise.title"></lay-input>
          </lay-form-item> 
          <lay-form-item label="跳转地址" prop="jumpURL">
            <lay-input v-model="advertise.jumpURL"></lay-input>
          </lay-form-item> 
          <lay-form-item label="到期时间" prop="expTime">
            <lay-date-picker type="datetime" v-model="advertise.expTime" placeholder="请选择广告到期时间"></lay-date-picker>
          </lay-form-item> 
          <lay-form-item label="展示页面" prop="showPage" mode="inline">
                <lay-select v-model="advertise.showPage"> 
                    <lay-select-option value="1" label="启动页面"></lay-select-option>
                    <lay-select-option value="2" label="首页轮播图"></lay-select-option>
                    <lay-select-option value="3" label="视频列表"></lay-select-option>
                    <lay-select-option value="4" label="视频播放页面"></lay-select-option>
                    <lay-select-option value="5" label="凤楼列表"></lay-select-option>
                    <lay-select-option value="6" label="凤楼详情"></lay-select-option>
                </lay-select>
            </lay-form-item>
 

        </lay-form>
      </div>
    </lay-layer>
  </template>
  
  <script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  import { allAdvertise, addAdvertise,reviseAdvertiseState,delAdvertise} from '../../api'
  import { getToken } from '@/utils/auth'
  import {getUrl} from '../../api/comfig'
  const  httpUrl=getUrl();

  const search = reactive({
    type: "0",
    state: "0",
  })
 

  
const headers= reactive({
  'token': getToken()
})
  
  const loading = ref(false);
  const dataSource = ref([]);
  const selectedKeys = ref([]);
  const page = reactive({ current: 1, limit: 10, total: 0 });
  const columns = ref([ 
    { title: "图片", key: "thumbnail", align: "center" , customSlot: "thumbnail"},
    { title: "标题", key: "title", align: "center" },
    { title: "跳转地址", key: "jumpURL", align: "center" },
    { title: "类型", key: "showPage", align: "center", customSlot: "showPage" },
    { title: "状态", key: "state", align: "center", customSlot: "state" }, 
    { title: "到期时间", key: "expTime", align: "center" },
    { title: "时间", key: "addTime", align: "center" },
    { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center" }
  ]);
  
  const getAllAdvertise = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "type": search.type,"state": search.state };
    allAdvertise(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  //获取数据
  getAllAdvertise();
  
  
  const change = (page: any) => {
    getAllAdvertise();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
  

//查看图片

const signleImg = (imgur: any) => {
  layer.photos({
    imgList: [{ src: imgur }]
  })
}
  //查询接口
  const searchUser = () => {
    page.current=1;
    getAllAdvertise();
  }
   
  //修改类型接口
  const changeState = (row: any) => { 
  var data = { "key": row.state, "id":row.id };
  reviseAdvertiseState(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
      getAllAdvertise();
      layer.msg("操作成功", { time: 1000, icon: 1 });
    }
  })
}
  
  //上传文件回调方法
  const getUploadFile2 = (res: any) => {
  res = JSON.parse(res.data);
  if (res.code == 0) {
    advertise.thumbnail = res.data.src;
 
    layer.msg("上传成功", { time: 1000, icon: 1 })
  } else {
    layer.msg("上传异常", { icon: 3, time: 1000 })
  }

}


  
  const advertise = reactive({
    id:0,
    title: "",
    thumbnail: "",
    jumpURL: "", 
    showPage: "", 
    state: "", 
    expTime: "", 
  })
  
  
  const deleAdvertise = (row: any) => {
       var data={'id':row.id} 
    delAdvertise(JSON.stringify(data)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllAdvertise(); 
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
   
  }
  
  const layFormRef11 = ref();
  
  const visible11 = ref(false);
  
  const isDisabled = ref(false);

  const updateAdvertise= (row: any) => {

    advertise.id = row.id;
    advertise.title =row.title;
    advertise.thumbnail = row.thumbnail;
    advertise.jumpURL =row.jumpURL;
    advertise.showPage =row.showPage;
    advertise.state = row.state;
    advertise.expTime = row.expTime;
  
    isDisabled.value = false;
    visible11.value = !visible11.value;
  }
  
  const changeVisible11 = () => {
    advertise.id = 0;
    advertise.title = "";
    advertise.thumbnail = "";
    advertise.jumpURL = "";
    advertise.showPage = "";
    advertise.state = "";
    advertise.expTime = "";
  
    isDisabled.value = false;
    visible11.value = !visible11.value;
  }
  
  const action11 = ref([
    {
      text: "确认",
      callback: () => {
        addAdvertise(JSON.stringify(advertise)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllAdvertise();
            visible11.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
      }
    },
    {
      text: "取消",
      callback: () => {
        visible11.value = false;
  
  
      }
    }
  ])
  
  
  </script>
  
  <style scoped></style>