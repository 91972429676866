<template>
  <lay-row space="10">
    <lay-col md="24">
      <lay-panel>
        <lay-form :model="search" style="text-align: start" size="sm">
          <lay-form-item label="节点" prop="username" mode="inline">
            <lay-input v-model="search.key" placeholder="请输入节点名称"></lay-input>
          </lay-form-item>
          <lay-form-item mode="inline">
            <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
          </lay-form-item>

        </lay-form>
        <div style="margin-bottom: 10px;text-align: left;">
          <lay-button size="sm" @click="addNode" type="primary">新增</lay-button>
        </div>

        <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
          v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">
          <template #state="{ row }">
            <lay-switch :model-value="row.state == 0 ? true : false" @change="changedelFlag(row)" onswitch-text="开启"
              unswitch-text="关闭"></lay-switch>
          </template>
          <template v-slot:passageImg="{ row }">
            <img :src="row.passageImg" style="width:60px;height:60px" />
          </template>



          <template v-slot:operator="{ row }">
            <lay-button size="xs" type="primary" @click="updateNode(row)">编辑</lay-button>
            <lay-button size="xs" type="primary" @click="basicConfigNode(row)">基本配置</lay-button>
            <lay-button size="xs" type="primary" @click="cacheConfigNode(row)">缓存配置</lay-button>
            <lay-button size="xs" type="primary" @click="pictureConfigNode(row)">图片配置</lay-button> 
            <lay-button size="xs" type="primary" @click="delNode(row)">删除</lay-button>
          </template>
        </lay-table>



      </lay-panel>

    </lay-col>

  </lay-row>


  <lay-layer v-model="visible11" :shade="false" :area="['450px', '540px']" :btn="action11" title="添加/修改节点">
    <div style="padding: 20px;">

      <lay-form :model="node" ref="layFormRef11">

        <lay-form-item label="图片" prop="passageImg">
          <lay-upload @done="getUploadFile2" :disabledPreview="false" :multiple="false" acceptMime="image/*"
            :url="httpUrl+'/common/imgUpload/node'" field="file" :headers="headers">
            <template #preview>
              <div class="easy-wrap" v-if="node.passageImg != ''">
                <img :src="httpUrl + node.passageImg" style="width:60px;height:60px" />
              </div>
            </template>
          </lay-upload>
        </lay-form-item>

        <lay-form-item label="名称节点" prop="userName">
          <lay-input v-model="node.passageName"></lay-input>
        </lay-form-item>

        <lay-form-item label="排序" prop="sortWeight">
          <lay-input v-model="node.sortWeight"></lay-input>
        </lay-form-item> 
        <lay-form-item label="备注" prop="notes">
      <lay-textarea placeholder="请输入备注" v-model="node.notes"></lay-textarea>
    </lay-form-item>
        

      </lay-form>
    </div>
  </lay-layer>


  <lay-layer v-model="basicConfigVisible1" :shade="false" :area="['450px', '680px']" :btn="basicConfigAction1"
    title="节点基本配置">
    <div style="padding: 20px;">
      <lay-form :model="nodeBasicConfig">
        <lay-form-item label="资源api" prop="userName">
          <lay-input v-model="nodeBasicConfig.resourceApi"></lay-input>
        </lay-form-item>

        <lay-form-item label="节点分类" prop="sortWeight">
          <lay-select v-model="nodeBasicConfig.categoryId" placeholder="请选择">
            <lay-select-option v-for="( value, key) in passageCategoryList" :key="key" :value="value.id" :label="value.categoryName"></lay-select-option>
          </lay-select>
        </lay-form-item>
        <lay-form-item label="站点选择" prop="sortWeight">
          <lay-select v-model="nodeBasicConfig.siteId" placeholder="请选择">
            <lay-select-option v-for="( value, key) in siteList" :key="key" :value="value.id" :label="value.siteName"></lay-select-option>
          </lay-select>
        </lay-form-item>
        <lay-form-item label="显示设备" prop="showDevice">
          <lay-radio v-model="nodeBasicConfig.showDevice" name="showDevice" :value="1" label="全部"></lay-radio>
          <lay-radio v-model="nodeBasicConfig.showDevice" name="showDevice" :value="2" label="安卓"></lay-radio>
          <lay-radio v-model="nodeBasicConfig.showDevice" name="showDevice" :value="3" label="苹果"></lay-radio>
          <lay-radio v-model="nodeBasicConfig.showDevice" name="showDevice" :value="4" label="pc"></lay-radio>
        </lay-form-item>

        <lay-form-item label="请求方式" prop="requestMethod">
          <lay-radio v-model="nodeBasicConfig.requestMethod" name="requestMethod" :value="0" label="接口请求"></lay-radio>
          <lay-radio v-model="nodeBasicConfig.requestMethod" name="requestMethod" :value="1" label="本地请求"></lay-radio>
        </lay-form-item>
        <lay-form-item label="模板选择" prop="templateType">
          <lay-radio v-model="nodeBasicConfig.templateType" name="templateType" :value="1" label="默认模板"></lay-radio>
          <lay-radio v-model="nodeBasicConfig.templateType" name="templateType" :value="2" label="模板1"></lay-radio>
        </lay-form-item>
        <lay-form-item label="搜索栏开关" prop="isSearch">
          <lay-radio v-model="nodeBasicConfig.isSearch" name="isSearch" :value="0" label="开启"></lay-radio>
          <lay-radio v-model="nodeBasicConfig.isSearch" name="isSearch" :value="1" label="关闭"></lay-radio>
        </lay-form-item>
        <lay-form-item label="类型选择" prop="sortWeight">
          <lay-select v-model="nodeBasicConfig.type" placeholder="请选择">
            <lay-select-option :value="1" label="视频"></lay-select-option>
            <lay-select-option :value="2" label="风楼"></lay-select-option>
            <lay-select-option :value="3" label="图文"></lay-select-option>
            <lay-select-option :value="4" label="跳转外部"></lay-select-option>
            <lay-select-option :value="5" label="分享地址"></lay-select-option>
            <lay-select-option :value="6" label="漫画"></lay-select-option>
            <lay-select-option :value="7" label="小说"></lay-select-option>
            <lay-select-option :value="8" label="直播"></lay-select-option>
            <lay-select-option :value="9" label="短视频"></lay-select-option>
            <lay-select-option :value="10" label="图文并茂"></lay-select-option>
            <lay-select-option :value="11" label="有声小说"></lay-select-option>
            <lay-select-option :value="12" label="交流群"></lay-select-option>
            <lay-select-option :value="13" label="关于"></lay-select-option>
            <lay-select-option :value="14" label="论坛"></lay-select-option>
            <lay-select-option :value="15" label="电视剧"></lay-select-option>
            <lay-select-option :value="16" label="电影"></lay-select-option>
          </lay-select>
        </lay-form-item>
        <lay-form-item label="请求页面请求" prop="detailHttp" v-if="nodeBasicConfig.type==2">
          <lay-radio v-model="nodeBasicConfig.detailHttp" name="isSearch" :value="0" label="关闭"></lay-radio>
          <lay-radio v-model="nodeBasicConfig.detailHttp" name="isSearch" :value="1" label="开启"></lay-radio>
        </lay-form-item>

        
        <lay-form-item label="地址" prop="shareAddress" v-if="nodeBasicConfig.type==4 || nodeBasicConfig.type==5">
          <lay-input v-model="nodeBasicConfig.shareAddress"></lay-input>
        </lay-form-item>

      </lay-form>
    </div>
  </lay-layer>

  <lay-layer v-model="cacheConfigVisible1" :shade="false" :area="['400px', '350px']" :btn="cacheConfigAction1" title="缓存设置">
    <div style="padding: 20px;">

      <lay-form :model="cacheConfig" ref="layFormRef11">

        <lay-form-item  prop="passageImg" style="text-align: center;">
          <lay-button type="danger" @click="delCacheConfig">删除缓存</lay-button>
        </lay-form-item>

        <lay-form-item label="列表缓存时间" prop="cacheTime">
          <lay-input v-model="cacheConfig.cacheTime"  placeholder="请输入缓存时间（分钟）"></lay-input>
        </lay-form-item>

        <lay-form-item label="详情缓存时间" prop="detailCacheTime">
          <lay-input v-model="cacheConfig.detailCacheTime" placeholder="请输入缓存时间（分钟）"></lay-input>
        </lay-form-item> 

      </lay-form>
    </div>
  </lay-layer>


  <lay-layer v-model="pictureConfigVisible1" :shade="false" :area="['400px', '450px']" :btn="pictureConfigAction1" title="图片解密设置">
    <div style="padding: 20px;">

      <lay-form :model="pictureConfig" ref="layFormRef11">

        <lay-form-item  label="解密方法" prop="passageImg">
          <lay-select v-model="pictureConfig.decryptionType" placeholder="请选择">
              <lay-select-option :value="0" label="无"></lay-select-option>
              <lay-select-option :value="1" label="方法1"></lay-select-option>
              <lay-select-option :value="2" label="方法2"></lay-select-option>
              <lay-select-option :value="3" label="方法3"></lay-select-option>
              <lay-select-option :value="4" label="方法4"></lay-select-option>
              <lay-select-option :value="5" label="方法5"></lay-select-option>
              <lay-select-option :value="6" label="方法6"></lay-select-option>
              <lay-select-option :value="7" label="方法7"></lay-select-option>
              <lay-select-option :value="8" label="方法8"></lay-select-option>
              <lay-select-option :value="9" label="方法9"></lay-select-option>
              <lay-select-option :value="10" label="方法10"></lay-select-option>
            </lay-select>
        </lay-form-item>

        <lay-form-item label="解密Key" prop="decryptKey">
          <lay-input v-model="pictureConfig.decryptKey"  placeholder="请输入解密Key"></lay-input>
        </lay-form-item>

        <lay-form-item label="解密IV" prop="decryptIv">
          <lay-input v-model="pictureConfig.decryptIv" placeholder="请输入解密IV"></lay-input>
        </lay-form-item> 

        <lay-form-item  label="服务器解密" prop="serverDecryption">
          <lay-select v-model="pictureConfig.serverDecryption" placeholder="请选择">
              <lay-select-option :value="0" label="出租需要解密"></lay-select-option>
              <lay-select-option :value="1" label="不解密"></lay-select-option>
              <lay-select-option :value="2" label="全部解密"></lay-select-option>  
            </lay-select>
        </lay-form-item>

        <lay-form-item label="图片网址" prop="serverUrl" v-if="pictureConfig.serverDecryption==0||pictureConfig.serverDecryption==2">
          <lay-input v-model="pictureConfig.serverUrl" placeholder="请输入图片服务器网址"></lay-input>
        </lay-form-item> 


      </lay-form>
    </div>
  </lay-layer>

  

</template>
  
<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { layer } from '@layui/layer-vue'
import { addPassage, allPassage, delPassage,allPassageCategory,allSite,basicConfigPassage,updatePassageState,updateCacheConfigPassage,updatePictureConfigPassage,
  delCacheConfigPassage} from '../../api'
  import { getToken } from '@/utils/auth'
  import {getUrl} from '../../api/comfig'
  const  httpUrl=getUrl();

const search = reactive({
  key: "",
})

const headers= reactive({
  'token': getToken()
})

const loading = ref(false);
const dataSource = ref([]);

const selectedKeys = ref([]);
const passageCategoryList = ref([]);
const siteList = ref([]);



const page = reactive({ current: 1, limit: 10, total: 0 });

const columns = ref([
  { title: "图片", width: "80px", key: "passageImg", align: "center", customSlot: "passageImg" },

  { title: "节点名称", key: "passageName", align: "center", width: "130px" },
  { title: "分类", key: "categoryName", align: "center", width: "130px" },
  { title: "站点名称", key: "siteName", align: "center", width: "130px" },
  { title: "站点网站", key: "website", align: "center", width: "200px" },
  { title: "站点api", key: "websiteApi", align: "center", width: "200px" },
  { title: "资源api", key: "resourceApi", align: "center", width: "200px" },
  { title: "状态", key: "state", align: "center", width: "200px", customSlot: "state" },
  { title: "备注", key: "notes", align: "center" },
  { title: "操作", customSlot: "operator", key: "operator", width: "380px", fixed: "right", align: "center" }
]);


const getAllPassage = () => {
  loading.value = true;
  var data = { "page": page.current, "limit": page.limit, "key": search.key };
  allPassage(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      dataSource.value = res.data;
      page.total = res.count;
    } else {
      dataSource.value = [];
      page.total = 0;
    }
  })
}
//获取数据
getAllPassage();


const getAllPassageCategory=()=>{ 
  var data = { "page":0, "limit": 300 };
  allPassageCategory(JSON.stringify(data)).then((res: any) => { 
    if (res != "" && res.code == 0) {
      passageCategoryList.value = res.data;
     
    }  
  })
}

getAllPassageCategory();

const getAllallSite=()=>{ 
  var data = { "page":0, "limit": 300 };
  allSite(JSON.stringify(data)).then((res: any) => { 
    if (res != "" && res.code == 0) {
      siteList.value = res.data;
     
    }  
  })
}

getAllallSite();





const change = (page: any) => {
  getAllPassage();
}

const sortChange = (key: any, sort: any) => {
  layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}

//禁用或者开启
  const changedelFlag = (row: any) => { 
    var data = { "key": row.id, "id": row.state };
    updatePassageState(JSON.stringify(data)).then((res: any) => {
      if (res != "" && res.code == 0) {
        getAllPassage();
        layer.msg("操作成功", { time: 1000, icon: 1 });
      }
    })
  }

//查询接口
const searchUser = () => {
  page.current = 1;
  getAllPassage();
}

//删除
const delNode = (row: any) => {
  var data = { 'id': row.id };
  delPassage(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
      getAllPassage();
      visible11.value = false;
      layer.msg("操作成功", { time: 1000, icon: 1 });
    }
  })


}


const node = reactive({
  passageName: "",
  passageImg: "",
  id: 0,
  sortWeight: 1,
  notes:"",
})


const updateNode = (row: any) => {
  node.passageName = row.passageName;
  node.passageImg = row.passageImg;
  node.sortWeight = row.sortWeight;
  node.notes = row.notes;
  node.id = row.id;
  visible11.value = !visible11.value;
}


//上传文件回调方法
const getUploadFile2 = (res: any) => {
  res = JSON.parse(res.data);
  if (res.code == 0) {
    node.passageImg = res.data.src;

    layer.msg("上传成功", { time: 1000, icon: 1 })
  } else {
    layer.msg("上传异常", { icon: 3, time: 1000 })
  }

}

const layFormRef11 = ref();

const visible11 = ref(false);

const addNode = () => {
  node.id = 0;
  node.passageName = "";
  node.passageImg = "";
  node.sortWeight = 1;
  visible11.value = !visible11.value;
}

const action11 = ref([
  {
    text: "确认",
    callback: () => {
      addPassage(JSON.stringify(node)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getAllPassage();
          visible11.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      visible11.value = false;


    }
  }
])


//基本配置
const nodeBasicConfig = reactive({
  resourceApi: "",
  categoryId: 0,
  id: 0,
  siteId: 0,
  showDevice: 1,
  requestMethod: 0,
  templateType: 1,
  isSearch: 0,
  type: 0,
  detailHttp:0,
  shareAddress:""
})

const basicConfigVisible1 = ref(false);

const basicConfigNode = (row: any) => {
  nodeBasicConfig.resourceApi = row.resourceApi;
  nodeBasicConfig.categoryId = row.categoryId;
  nodeBasicConfig.showDevice = row.showDevice;
  nodeBasicConfig.showDevice = row.showDevice;
  nodeBasicConfig.requestMethod = row.requestMethod;
  nodeBasicConfig.siteId = row.siteId;
  nodeBasicConfig.id = row.id;
  nodeBasicConfig.isSearch = row.isSearch;
  nodeBasicConfig.type = row.type;
  nodeBasicConfig.templateType = row.templateType;
  nodeBasicConfig.detailHttp = row.detailHttp;
  nodeBasicConfig.shareAddress = row.shareAddress;
  
  basicConfigVisible1.value = !basicConfigVisible1.value;
}


const basicConfigAction1 = ref([
  {
    text: "确认",
    callback: () => {
      basicConfigPassage(JSON.stringify(nodeBasicConfig)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getAllPassage();
          basicConfigVisible1.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      basicConfigVisible1.value = false;


    }
  }
])




//缓存配置
 
const cacheConfig = reactive({
  cacheTime: "",
  detailCacheTime: "",
  id: 0,
 
})

const cacheConfigVisible1 = ref(false);

const cacheConfigNode = (row: any) => {
  cacheConfig.cacheTime = row.cacheTime;
  cacheConfig.detailCacheTime = row.detailCacheTime;
  
  cacheConfig.id = row.id; 
  cacheConfigVisible1.value = !cacheConfigVisible1.value;
}


const cacheConfigAction1 = ref([
  {
    text: "确认",
    callback: () => {
      updateCacheConfigPassage(JSON.stringify(cacheConfig)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getAllPassage();
          cacheConfigVisible1.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      cacheConfigVisible1.value = false;


    }
  }
])


//删除缓存
const  delCacheConfig=()=>{  
  delCacheConfigPassage(JSON.stringify(cacheConfig)).then((res: any) => {
        if (res != "" && res.code == 0) { 
         
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })


}


//图片配置
const pictureConfig = reactive({
  decryptionType: 0,
  decryptKey: "",
  decryptIv:'',
  serverUrl:'',
  serverDecryption :0,
  id: 0,
 
})

const pictureConfigVisible1 = ref(false);

const pictureConfigNode = (row: any) => {
  pictureConfig.decryptionType = row.decryptionType;
  pictureConfig.decryptKey = row.decryptKey;
  pictureConfig.decryptIv = row.decryptIv;
  pictureConfig.serverUrl = row.serverUrl;
  pictureConfig.serverDecryption = row.serverDecryption; 
  
  pictureConfig.id = row.id; 
  pictureConfigVisible1.value = !pictureConfigVisible1.value;
}


const pictureConfigAction1 = ref([
  {
    text: "确认",
    callback: () => {
      updatePictureConfigPassage(JSON.stringify(pictureConfig)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getAllPassage();
          pictureConfigVisible1.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      pictureConfigVisible1.value = false;


    }
  }
])



 


</script>
  
<style scoped></style>